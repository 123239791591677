import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';

const routes: Routes = [

    {
        path: 'login',
        loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
    },
    {
        path: 'termo',
        loadChildren: () => import('./pages/termo/termo.module').then(m => m.TermoPageModule)
    },
    {
        path: 'confirmacao-de-dados',
        loadChildren: () => import('./pages/confimacao-de-dados/confirmacao-de-dados.module').then(m => m.ConfirmacaoDeDadosModule)
    },
    {
        path: 'dados-incorretos',
        loadChildren: () => import('./pages/dados-incorretos/dados-incorretos.module').then(m => m.DadosIncorretosPageModule)
    },
    {
        path: 'carta-de-orientacao',
        loadChildren: () => import('./pages/carta-de-orientacao/carta-de-orientacao.module').then(m => m.CartaDeOrientacaoPageModule)
    },
    {
        path: 'dps',
        loadChildren: () => import('./pages/dps/dps.module').then(m => m.DpsPageModule)
    },
    {
        path: 'declaracao-de-saude',
        loadChildren: () => import('./pages/declaracao-de-saude/declaracao-de-saude.module').then(m => m.DeclaracaoDeSaudePageModule)
    },
    {
        path: 'finalizar-assinatura',
        loadChildren: () => import('./pages/finalizar-assinatura/finalizar-assinatura.module').then(m => m.FinalizarAssinaturaPageModule)
    },
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
