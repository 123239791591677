import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {TokenService} from './token.service';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class RequestInterceptorService implements HttpInterceptor {

    constructor(private tokenService: TokenService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const token = this.tokenService.geraToken();

        const headers = new HttpHeaders({
            Token: `${token}`,
            Accept: '*/*',
            'Content-Type': 'application/json'
        });

        console.log(token);
        const cloneReq = req.clone({headers});

        return next.handle(cloneReq);
    }

}
