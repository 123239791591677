import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {Md5} from 'ts-md5/dist/md5';

@Injectable({
    providedIn: 'root'
})
export class TokenService {

    base;

    constructor() {
        this.base = environment.base;
    }

    geraToken() {
        const md5 = new Md5();
        const currentDate = new Date();

        let numberMonth: any = currentDate.getMonth() + 1;
        let day: any = currentDate.getDate();

        if (numberMonth < 10) {
            numberMonth = `0${numberMonth}`;
        }

        if (day < 10) {
            day = `0${day}`;
        }

        const stringToken = `${this.base}`;
        return md5.appendStr(stringToken).end();

    }
}
